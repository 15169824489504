import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Hr from '../Elements/Hr';

const Container = styled.div<{ margin: string }>`
  &&& {
    display: flex;
    align-items: center;
    width: 100%;
    margin: ${({ margin }) => margin};
  }
`;

const StyledSmall = styled.small`
  && {
    flex: 1;
    text-align: center;
  }
`;

const StyledHr = styled(Hr)`
  flex-basis: 30%;
`;

const dividerPropertyTypes = {
  dividerText: PropTypes.string,
  margin: PropTypes.string,
};

type DividerProperties = InferProps<
typeof dividerPropertyTypes
>;

const Divider = ({
  dividerText,
  margin,
}: DividerProperties) => (
  <Container margin={margin ?? '48px 0 32px'}>
    <StyledHr backgroundColor="var(--black-disabled)" />
    <StyledSmall>{dividerText}</StyledSmall>
    <StyledHr backgroundColor="var(--black-disabled)" />
  </Container>
);

Divider.defaultProps = {
  dividerText: undefined,
};

Divider.propTypes = dividerPropertyTypes;

export default Divider;
