import React from 'react';
import { LoginSignupLayout }
from '@fuww/library/src/Login';
import Hr from '@fuww/library/src/Elements/Hr';
import Divider from '@fuww/library/src/Login/Divider';
import Button from '@fuww/library/src/Button';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import LoginWithProviderForm from './LoginWithProviderForm';
import Disclaimer from './Disclaimer';
import Head from '../Head';
import HomeLink from '../HomeLink';
import LinkAndAnchor from '../LinkAndAnchor';
import { Link } from '../../routes.mjs';
import CookieConsentMessage from '../CookieConsent/Message';
import messages from '../../lib/messages.mjs';
import RedirectWhenLoggedIn from './RedirectWhenLoggedIn';

const Login = ({ showBreadcrumbs }) => {
  const intl = useIntl();

  const breadcrumbs = showBreadcrumbs ? [
    <HomeLink />,
    <LinkAndAnchor
      route="login"
    >
      {intl.formatMessage(messages.login)}
    </LinkAndAnchor>,
  ] : [];

  return (
    <LoginSignupLayout
      title={intl.formatMessage(messages['login.title'])}
      disclaimer={(<Disclaimer />)}
      breadcrumbs={breadcrumbs}
    >
      <Head
        title={intl.formatMessage(messages['login.title'])}
        description={
          intl.formatMessage(messages['markets.description'])
        }
      />
      <CookieConsentMessage />
      <LoginForm />
      <Divider dividerText="OR CONTINUE WITH" />
      <LoginWithProviderForm
        provider="google"
      />
      <LoginWithProviderForm
        provider="linkedin"
      />
      <Link route="register" passHref>
        <Button fullWidth variant="text" className="create-account">
          {intl.formatMessage(messages['register.createAccount'])}
        </Button>
      </Link>
      <Hr backgroundColor="var(--black-disabled)" margin="24px 0" />
      <RedirectWhenLoggedIn />
    </LoginSignupLayout>
  );
};

Login.propTypes = {
  showBreadcrumbs: PropTypes.bool,
};

Login.defaultProps = {
  showBreadcrumbs: true,
};

export default Login;
