import React, { ChangeEventHandler, useCallback, ComponentProps } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Controller } from 'react-hook-form';
import MUITextField from '@mui/material/TextField';
import IconButton from '../IconButton';
import InputAdornment from '../InputAdornment';
import coalesceWithEmptyString from '../lib/coalesceWithEmptyString';
import ClearIcon from '../Icons/Clear';

export type Variant = 'filled' | 'outlined' | 'standard';

const textFieldPropertyTypes = {
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined'] as Variant[]),
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  InputProps: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  clearInput: PropTypes.func,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
};

export type TextFieldProperties = Omit<Omit<InferProps<
  typeof textFieldPropertyTypes
>, 'onChange'>, 'clearInput'> & {
  InputProps?: {
    endAdornment?: React.ReactNode;
    disableUnderline?: boolean;
  };
  onChange?: ChangeEventHandler<HTMLInputElement>;
  clearInput?: (name: string) => void;
};

const TextField = React.forwardRef(
  (
    {
      variant,
      type,
      label,
      id,
      name,
      onChange,
      onFocus,
      value,
      fullWidth,
      multiline,
      rows,
      disabled,
      InputProps,
      inputProps,
      clearInput,
      onBlur,
      ...rest
    } : TextFieldProperties,
    reference : React.ForwardedRef<HTMLDivElement>,
  ) => (
    <MUITextField
      {...rest}
      variant={variant ?? 'outlined'}
      type={type ?? 'text'}
      label={label}
      id={id}
      name={name}
      onChange={onChange}
      onFocus={onFocus ?? undefined}
      value={coalesceWithEmptyString(value)}
      fullWidth={fullWidth ?? false}
      multiline={multiline ?? false}
      rows={rows ?? undefined}
      disabled={disabled ?? false}
      ref={reference}
      onBlur={onBlur ?? undefined}
      InputProps={{
        ...variant === 'filled' ? {
          disableUnderline: true,
        } : {},
        ...clearInput ? {
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() => clearInput(name)}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        } : {},
        ...InputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps ?? undefined}
    />
  ),
);

const textFieldWithControllerPropertyTypes = {
  ...textFieldPropertyTypes,
  rules: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  transformValueOnChange: PropTypes.func,
  onChangeHook: PropTypes.func,
  showClearInput: PropTypes.bool,
  onBlur: PropTypes.func,
};

type TextFieldWithControllerProperties = Omit<Omit<InferProps<
typeof textFieldWithControllerPropertyTypes
>, 'transformValueOnChange'>, 'control'> & {
  control: ComponentProps<typeof Controller>['control'];
  rules: ComponentProps<typeof Controller>['rules'];
  transformValueOnChange?: (value: string) => string;
};

export const TextFieldWithController = ({
  rules,
  name,
  control,
  defaultValue,
  variant,
  type,
  label,
  id,
  fullWidth,
  multiline,
  rows,
  disabled,
  InputProps,
  inputProps,
  helperText,
  onFocus,
  transformValueOnChange,
  showClearInput,
  onChangeHook,
  maxLength,
  onBlur,
}: TextFieldWithControllerProperties) => {
  const render = useCallback(
    ({
      onChange, value, ref,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      value: string;
      // eslint-disable-next-line react/no-unused-prop-types
      ref: React.RefObject<HTMLInputElement>;
      // eslint-disable-next-line react/no-unused-prop-types
      onChange: (value: string) => void;
    }) => (
      <TextField
        onChange={({ target: { value: newValue } }) => {
          const transformedValue: string = transformValueOnChange
            ? transformValueOnChange(newValue)
            : newValue;
          onChange(transformedValue);
          onChangeHook?.();
        }}
        value={value}
        ref={ref}
        label={label}
        variant={variant}
        type={type}
        id={id}
        name={name}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        onFocus={onFocus}
        helperText={helperText}
        InputProps={InputProps ?? undefined}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={inputProps}
        clearInput={showClearInput ? () => {
          onChange('');
          onChangeHook?.();
        } : undefined}
        maxLength={maxLength}
        onBlur={onBlur}
      />
    ),
    [
      variant,
      type,
      label,
      id,
      name,
      fullWidth,
      multiline,
      rows,
      disabled,
      InputProps,
      inputProps,
      helperText,
      onFocus,
      transformValueOnChange,
      showClearInput,
      onChangeHook,
      maxLength,
      onBlur,
    ],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={render}
    />
  );
};

TextFieldWithController.propTypes = textFieldWithControllerPropertyTypes;

export default TextField;
